import React, { useState } from "react";
import { Button, FormControl, InputAdornment, TextField } from "@mui/material";
import {
  AddCircle,
  Article,
  CalendarToday,
  Link,
  Newspaper,
  Person,
} from "@mui/icons-material";
import logo1 from "../../assets/logo/White Logo only.png";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { isError, isLoading, isSuccess } from "../../redux/actions/blogAction";
import {
  setBlogBody,
  setBlogDate,
  setBlogPublisher,
  setBlogTitle,
  setVideoURL,
} from "../../redux/actions/blogAction";

export function PostBlogForm({ fileObjects }) {
  const dispatch = useDispatch();
  const [errors, setErrors] = useState({
    title: false,
    body: false,
    publisher: false,
    date: false,
    videoURL: false,
    fileObjects: false,
  });
  const blogTitle = useSelector((state) => state.blog.blogTitle);
  const blogBody = useSelector((state) => state.blog.blogBody);
  const blogPublisher = useSelector((state) => state.blog.blogPublisher);
  const blogDate = useSelector((state) => state.blog.blogDate);
  const videoURL = useSelector((state) => state.blog.videoURL);
  const loading = useSelector((state) => state.blog.loading);
  const token = useSelector((state) => state.login.accessToken);

  const addBlogTitle = (e) => {
    dispatch(setBlogTitle(e.target.value));
  };
  const addBlogBody = (e) => {
    dispatch(setBlogBody(e.target.value));
  };
  const addBlogPublisher = (e) => {
    dispatch(setBlogPublisher(e.target.value));
  };
  const addBlogDate = (e) => {
    dispatch(setBlogDate(e.target.value));
  };
  const addVideoURL = (e) => {
    dispatch(setVideoURL(e.target.value));
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    // Validate input fields
    const validationErrors = {
      title: !blogTitle.trim(),
      body: !blogBody.trim(),
      publisher: !blogPublisher.trim(),
      date: !blogDate,
      videoURL: !videoURL.trim(),
      fileObjects: fileObjects.length === 0,
    };

    // Display errors for empty fields
    setErrors(validationErrors);

    // Check if any field is empty
    if (Object.values(validationErrors).some((error) => error)) {
      return;
    }

    // Scroll to top and start loading
    window.scrollTo(0, 0);
    dispatch(isLoading(true));

    try {
      // Submit blog data
      const blogData = {
        Title: blogTitle,
        Body: blogBody,
        Author: blogPublisher,
        Video_Link: videoURL,
      };
      const response = await axios.post(
        "https://api.octoberbreastcancer.com/api/blog",
        blogData
      );

      if (response.status === 200) {
        console.log("Blog submitted successfully:", response.data);

        // Upload images
        const formDataArray = fileObjects.map((file, index) => {
          const formData = new FormData();
          formData.append("image", file);
          return formData;
        });

        // Array to store all upload promises
        const uploadPromises = formDataArray.map((formData, index) => {
          const apiUrl = `https://api.octoberbreastcancer.com/api/Upload/image/Blog/${
            response.data
          }/${index + 1}`;
          return axios.post(apiUrl, formData, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          });
        });

        // Wait for all upload promises to resolve
        Promise.all(uploadPromises)
          .then((responses) => {
            console.log("All images uploaded successfully:", responses);
            dispatch(isSuccess("Blog published successfully"));
            dispatch(isLoading(false));
          })
          .catch(async (error) => {
            console.error("Error uploading images:", error);
            dispatch(isError("There was an error uploading images"));
            dispatch(isLoading(false));

            // Delete blog if image upload fails
            try {
              const config = {
                headers: {
                  Authorization: `Bearer ${token}`,
                },
              };

              await axios.delete(
                `https://api.octoberbreastcancer.com/api/blog/Delete/${response.data}`,
                config
              );
              console.log("Blog deleted successfully");
            } catch (deleteError) {
              console.error("Error deleting blog:", deleteError);
            }
          });
      } else {
        alert("Failed to submit blog");
      }
    } catch (error) {
      console.error("Error sending blog:", error);
      dispatch(isError("There was an error submitting the blog"));
      dispatch(isLoading(false));
    }
  };


  return (
    <FormControl variant="standard">
      <div className="flex w-full justify-between gap-8">
        <div className="flex flex-col gap-5">
          <TextField
            placeholder="BLOG TITLE"
            className="bg-white text-sm rounded-xl focus:ring-0 capitalize"
            style={{
              width: 500,
            }}
            sx={{
              "& .MuiOutlinedInput-root": {
                "&.Mui-focused fieldset": {
                  borderColor: errors.title ? "red" : "#A87FFF", //styles the border when focused
                },
              },
            }}
            variant="outlined"
            error={errors.title}
            helperText={errors.title && "Title is required"}
            InputProps={{
              style: {
                fontSize: 14,
                borderRadius: 12,
                borderWidth: "1px",
                borderColor: "white",
              },
              startAdornment: (
                <InputAdornment disableTypography position="start">
                  <Newspaper className="p-0.5 text-blogcolor" />
                </InputAdornment>
              ),
            }}
            onChange={(e) => addBlogTitle(e)}
          />
          <TextField
            placeholder="BLOG"
            className="bg-white text-sm rounded-xl focus:ring-0 capitalize"
            style={{
              width: 500,
            }}
            sx={{
              "& .MuiOutlinedInput-root": {
                "&.Mui-focused fieldset": {
                  borderColor: errors.body ? "red" : "#A87FFF", //styles the border when focused
                },
              },
            }}
            variant="outlined"
            error={errors.body}
            helperText={errors.body && "Body is required"}
            multiline
            rows={8}
            InputProps={{
              style: {
                fontSize: 14,
                borderRadius: 12,
                borderWidth: "1px",
                borderColor: "white",
              },
              startAdornment: (
                <InputAdornment
                  disableTypography
                  position="start"
                  style={{
                    alignSelf: "flex-start",
                    marginTop: "8px",
                  }}
                >
                  <Article className="p-0.5 text-blogcolor" />
                </InputAdornment>
              ),
            }}
            onChange={(e) => addBlogBody(e)}
          />
        </div>
        <div className="flex flex-col justify-between">
          <div className="flex flex-col gap-5">
            <TextField
              placeholder="VIDEO LINK"
              className="bg-white text-sm rounded-xl focus:ring-0 capitalize"
              style={{
                width: 500,
              }}
              sx={{
                "& .MuiOutlinedInput-root": {
                  "&.Mui-focused fieldset": {
                    borderColor: errors.videoURL ? "red" : "#A87FFF", //styles the border when focused
                  },
                },
              }}
              variant="outlined"
              error={errors.videoURL}
              helperText={errors.videoURL && "Video link is required"}
              InputProps={{
                style: {
                  fontSize: 14,
                  borderRadius: 12,
                  borderWidth: "1px",
                  borderColor: "white",
                },
                startAdornment: (
                  <InputAdornment disableTypography position="start">
                    <Link className="p-0.5 text-blogcolor" />
                  </InputAdornment>
                ),
              }}
              onChange={(e) => addVideoURL(e)}
            />
            <TextField
              placeholder="PUBLISHED BY"
              className="bg-white text-sm rounded-xl focus:ring-0 capitalize"
              style={{
                width: 500,
              }}
              sx={{
                "& .MuiOutlinedInput-root": {
                  "&.Mui-focused fieldset": {
                    borderColor: errors.publisher ? "red" : "#A87FFF", //styles the border when focused
                  },
                },
              }}
              variant="outlined"
              error={errors.publisher}
              helperText={errors.publisher && "Publisher is required"}
              InputProps={{
                style: {
                  fontSize: 14,
                  borderRadius: 12,
                  borderWidth: "1px",
                  borderColor: "white",
                },
                startAdornment: (
                  <InputAdornment disableTypography position="start">
                    <Person className="p-0.5 text-blogcolor" />
                  </InputAdornment>
                ),
              }}
              onChange={(e) => addBlogPublisher(e)}
            />
            <TextField
              placeholder="PUBLISHED DATE"
              type="date"
              className="bg-white text-sm rounded-xl focus:ring-0 capitalize"
              style={{
                width: 500,
              }}
              sx={{
                "& .MuiOutlinedInput-root": {
                  "&.Mui-focused fieldset": {
                    borderColor: errors.date ? "red" : "#A87FFF", //styles the border when focused
                  },
                },
              }}
              variant="outlined"
              error={errors.date}
              helperText={errors.date && "Date is required"}
              InputProps={{
                style: {
                  fontSize: 14,
                  borderRadius: 12,
                  borderWidth: "1px",
                  borderColor: "white",
                },
                startAdornment: (
                  <InputAdornment disableTypography position="start">
                    <CalendarToday className="p-0.5 text-blogcolor" />
                  </InputAdornment>
                ),
              }}
              onChange={(e) => addBlogDate(e)}
            />
          </div>
          <Button
            component="label"
            variant="contained"
            style={{
              backgroundColor: "#A87FFF",
              borderRadius: 8,
              color: "#fff",
              paddingTop: 10,
              paddingBottom: 10,
            }}
            startIcon={!loading && <AddCircle />}
            disableElevation={true}
            onClick={(e) => handleSubmit(e)}
          >
            {loading ? (
              <img src={logo1} className="w-7 animate-spin z-50" alt="" />
            ) : (
              "Post Blog"
            )}
          </Button>
        </div>
      </div>
      {errors.fileObjects && (
        <div className="text-red-500">
          Please select at least one image to upload
        </div>
      )}
    </FormControl>
  );
}
