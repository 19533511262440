
export const setNewsTitle = (title) => ({
  type: "SET_NEWS_TITLE",
  payload: title,
});

export const setNewsBody = (body) => ({
  type: "SET_NEWS_BODY",
  payload: body,
});
export const setNewsPublisher = (publisher) => ({
  type: "SET_NEWS_PUBLISHER",
  payload: publisher,
});
export const setNewsDate = (date) => ({
  type: "SET_NEWS_DATE",
  payload: date,
});
export const isLoading = (loading) => ({
  type: "NEWS_LOADING_STATE",
  payload:loading
})
export const isError = (error) => ({
  type: "NEWS_ERROR_STATE",
  payload: error,
});
export const isSuccess = (success) => ({
  type: "NEWS_SUCCESS_STATE",
  payload: success
});

export const fetchNewsSuccess = (news) => ({
  type: 'FETCH_NEWS_SUCCESS',
  payload: news,
});

export const fetchNewsFailure = (error) => ({
  type: 'FETCH_NEWS_FAILURE',
  payload: error,
});