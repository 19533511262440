import { AddCircle, Person2TwoTone } from "@mui/icons-material";
import React from "react";
import blog from "../assets/3d images/Blog.png";
// import DonationTable from "../components/common/DonationTable";
import { Link } from "react-router-dom";
import BlogsTable from "../components/common/BlogsTable";
import { useSelector } from "react-redux";

const Blogs = () => {
  const blogCount = useSelector((state) => state.blog.allBlogs);

  return (
    <div className="mt-7 w-full">
      <div className="w-full flex items-center gap-12 relative p-10 bg-white shadow-lg rounded-xl h-44">
        <div className="p-3 w-32 bg-blogcolor flex items-center justify-center rounded-xl text-white shadow-xl absolute right-10 -top-5">
          <Person2TwoTone className="" />
        </div>
        <img src={blog} className="w-40 h-fit scale-x-[-1]" alt="" />
        <div className="w-full flex justify-between items-end">
          <div className="space-y-1">
            <h1 className="font-bold text-2xl uppercase tracking-widest text-customBlack">
              Blogs
            </h1>
            <h1 className="font-bold text-3xl uppercase">
              {blogCount.length === 0 ? "..." : blogCount.length}
            </h1>
            <p className="capitalize tracking-wider">
              Total amount of{" "}
              <span className="text-blogcolor font-bold">blogs posted</span>
            </p>
          </div>
          <Link
            to={"/blogs/post"}
            className="flex items-center gap-3 text-white bg-blogcolor p-3 px-9 rounded-lg tracking-widest hover:bg-purple-600 duration-200"
          >
            <AddCircle />
            Post
          </Link>
        </div>
      </div>
      <h1 className="font-bold uppercase text-customBlack mt-10 mb-4 tracking-wide">
        Blogs published
      </h1>
      <BlogsTable />
    </div>
  );
};

export default Blogs;
