import { Route, Routes } from "react-router-dom";
import SideBar from "./global/SideBar";
import Dashboard from "./pages/Dashboard";
import Donations from "./pages/Donations";
import Membership from "./pages/Membership";
import Blogs from "./pages/Blogs";
import Newsfeed from "./pages/Newsfeed";
import Account from "./pages/Account";
import Navbar from "./global/Navbar";
import BlogPost from "./pages/BlogPost";
import NewsPost from "./pages/NewsPost";
import Login from "./pages/Login";
import ErrorModule from "./components/common/ErrorModule";
import LoadingModule from "./components/common/LoadingModule";
import SuccessModule from "./components/common/SuccessModule";
import { useSelector } from "react-redux";
import { useEffect } from "react";
import DonorDetail from "./pages/DonorDetail";
import AddAdminModule from "./components/common/AddAdminModule";
import NewsDetail from "./pages/NewsDetails";
import BlogDetail from "./pages/BlogDetails";
import BlogErrorModule from "./components/common/BlogErrorModule";
import BlogSuccessModule from "./components/common/BlogSuccessModule";
import BlogLoadingModule from "./components/common/BlogLoadingModule";
import MemberDetail from "./pages/MemberDetail";

function App() {
  const isLogged = useSelector((state) => state.login.isLogged);

  useEffect(() => {
    if (isLogged) {
      document.body.style.overflow = "scroll";
    } else {
      document.body.style.overflow = "hidden";
    }
  }, [isLogged]);

  return (
    <>
      <Login />
      <AddAdminModule />
      <LoadingModule />
      <SuccessModule />
      <ErrorModule />
      <BlogLoadingModule />
      <BlogErrorModule />
      <BlogSuccessModule />
      <SideBar />
      <div
        className={`absolute right-[5%] ${"left-[20%]"} top-[10%] duration-200`}
      >
        <Navbar />
        <Routes>
          <Route path="/" element={<Dashboard />} />
          <Route path="/donations" element={<Donations />} />
          <Route path="/membership" element={<Membership />} />
          <Route path="/newsfeed" element={<Newsfeed />} />
          <Route path="/news/post" element={<NewsPost />} />
          <Route path="/blogs" element={<Blogs />} />
          <Route path="/blogs/post" element={<BlogPost />} />
          <Route path="/account" element={<Account />} />
          <Route path="/donorDetail/:DonarId" element={<DonorDetail />} />
          <Route path="/memberDetail/:MembersId" element={<MemberDetail />} />
          <Route path="/news/:NewsId" element={<NewsDetail />} />
          <Route path="/blogs/:BlogId" element={<BlogDetail />} />
        </Routes>
      </div>
    </>
  );
}

export default App;
