import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux"; 
import { TextField, Button, InputAdornment, FormControl, IconButton } from "@mui/material";
import { Person, Lock, Person2TwoTone, Login, AddBox, Close } from "@mui/icons-material";
import logo1 from "../../assets/logo/White Logo only.png";
import { setAdminPassword, setAdminType, setAdminUsername, showAdminModule, showingAdminLoading } from "../../redux/actions/adminActions";
import axios from "axios";

const AddAdminModule = () => {
  const showAdmin = useSelector((state) => state.admin.showAdminModule);
  const username = useSelector((state) => state.admin.username);
  const password = useSelector((state) => state.admin.password);
  const type = useSelector((state) => state.admin.type);
  const isLoading = useSelector((state) => state.admin.loading);
  const dispatch=useDispatch()

   useEffect(() => {
     if (showAdmin) {
       document.body.style.overflow = "hidden";
     } else {
       document.body.style.overflow = "scroll"; // Set overflow to "scroll" when error is cleared
     }
   }, [showAdmin]);
  
   const handleLogin = async (e) => {
     e.preventDefault();
     const credentials = {
       UserName: username,
       Password: password,
       Type: type,
     };

    try {
      dispatch(showingAdminLoading(true));
      const response = await axios.post(
        "https://api.octoberbreastcancer.com/api/Admin",
        credentials
      );

      if (response.status === 200) {
        console.log("Succesfulllllll")
        dispatch(showAdminModule(false))
        dispatch(showingAdminLoading(false));
      }
    } catch (error) {
      console.error("Error during login:", error);
      // Dispatch an error action if needed
      dispatch(showingAdminLoading(false));
    }
  };
    const handleClose = () => {
      dispatch(showAdminModule(false));
    };

  return (
    showAdmin && (
      <div className="absolute h-screen z-[100] w-full text-2xl flex items-center justify-center bg-black bg-opacity-10">
        <div className="bg-white p-8 h-auto rounded-lg w-[29rem] relative">
          <FormControl className="flex flex-col items-center justify-center">
            <IconButton
              onClick={handleClose}
              className="absolute -top-5 right-2"
              size="small"
            >
              <Close />
            </IconButton>
            <TextField
              placeholder="Username"
              className="bg-transparent tracking-wider m3 text-sm rounded-xl focus:ring-0 capitalize"
              style={{
                width: 400,
                margin: 6,
              }}
              sx={{
                "& .MuiOutlinedInput-root": {
                  "&.Mui-focused fieldset": {
                    borderColor: "#FF7FCE", //styles the border when focused
                  },
                },
              }}
              variant="outlined"
              InputProps={{
                style: {
                  fontSize: 14,
                  borderRadius: 12,
                  borderColor: "white",
                },
                startAdornment: (
                  <InputAdornment disableTypography position="start">
                    <Person className="p-0.5 text-primary" />
                  </InputAdornment>
                ),
              }}
              onChange={(e) => dispatch(setAdminUsername(e.target.value))}
            />
            <TextField
              placeholder="Password"
              className="bg-transparent tracking-wider m3 text-sm rounded-xl focus:ring-0 capitalize"
              style={{
                width: 400,
                margin: 6,
              }}
              sx={{
                "& .MuiOutlinedInput-root": {
                  "&.Mui-focused fieldset": {
                    borderColor: "#FF7FCE", //styles the border when focused
                  },
                },
              }}
              variant="outlined"
              InputProps={{
                style: {
                  fontSize: 14,
                  borderRadius: 12,
                  borderColor: "white",
                },
                startAdornment: (
                  <InputAdornment disableTypography position="start">
                    <Lock className="p-0.5 text-primary" />
                  </InputAdornment>
                ),
              }}
              onChange={(e) => dispatch(setAdminPassword(e.target.value))}
            />
            <TextField
              select
              label="Admin Type"
              onChange={(e) => dispatch(setAdminType(e.target.value))}
              className="bg-transparent tracking-wider m3 text-sm rounded-xl focus:ring-0 capitalize"
              style={{
                width: 400,
                margin: 6,
              }}
              sx={{
                "& .MuiOutlinedInput-root": {
                  "&.Mui-focused fieldset": {
                    borderColor: "#FF7FCE", //styles the border when focused
                  },
                },
              }}
              variant="outlined"
              InputProps={{
                style: {
                  fontSize: 14,
                  borderRadius: 12,
                  borderColor: "white",
                },
                startAdornment: (
                  <InputAdornment disableTypography position="start">
                    <Person2TwoTone className="p-0.5 text-primary" />
                  </InputAdornment>
                ),
              }}
            >
              <option className="cursor-pointer" value="admin">
                Admin
              </option>
              <option className="cursor-pointer" value="superadmin">
                Super Admin
              </option>
            </TextField>
            <Button
              component="label"
              disableElevation={true}
              variant="contained"
              style={{
                backgroundColor: "#FF7FCE",
                borderRadius: 12,
                color: "#fff",
                paddingTop: 12,
                paddingBottom: 12,
                height: 50,
                width: 400,
                marginTop: 26,
              }}
              startIcon={!isLoading && <AddBox />}
              onClick={(e) => handleLogin(e)}
            >
              {isLoading ? (
                <img src={logo1} className="w-7 animate-spin" alt="" />
              ) : (
                "Add Admin"
              )}
            </Button>
          </FormControl>
        </div>
      </div>
    )
  );
}; 

export default AddAdminModule;
