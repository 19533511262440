
export const setBlogTitle = (title) => ({
  type: "SET_BLOG_TITLE",
  payload: title,
});

export const setBlogBody = (body) => ({
  type: "SET_BLOG_BODY",
  payload: body,
});
export const setBlogPublisher = (publisher) => ({
  type: "SET_BLOG_PUBLISHER",
  payload: publisher,
});
export const setBlogDate = (date) => ({
  type: "SET_BLOG_DATE",
  payload: date,
});
export const setVideoURL = (URL) => ({
  type: "SET_URL",
  payload: URL,
});
export const isLoading = (loading) => ({
  type: "BLOG_LOADING_STATE",
  payload:loading
})
export const isError = (error) => ({
  type: "BLOG_ERROR_STATE",
  payload: error,
});
export const isSuccess = (success) => ({
  type: "BLOG_SUCCESS_STATE",
  payload: success
});

export const fetchBlogSuccess = (blog) => ({
  type: "FETCH_BLOG_SUCCESS",
  payload: blog,
});

export const fetchBlogFailure = (error) => ({
  type: "FETCH_BLOG_FAILURE",
  payload: error,
});