import React from 'react'
import PersonIcon from "@mui/icons-material/Person";
import { useSelector } from 'react-redux';

const Navbar = ({ activeRoute }) => {
  const username = useSelector((state) => state.login.loggeduser);
  return (
    <div className="relative -z-30 w-full h-12 bg-backgroundDim">
      <nav className={`absolute w-full top-[5%] flex duration-200`}>
        <div className="flex items-center w-full justify-between h-full gap-8">
          <h1 className="font-light tracking-widest text-sm text-black">
            ADMIN PANEL
          </h1>
          <div className="flex items-center justify-between">
            <h1 className="font-bold text-xl tracking-[0.3rem]">
              {activeRoute === "/"
                ? "DASHBOARD"
                : activeRoute === "/virtualseminar" && "VIRTUAL SEMINARIES"}
            </h1>
            <div className="flex items-center justify-center text-end gap-3">
              <div className="flex items-center gap-3">
                <p className="text-sm font-bold text-slate-600 capitalize">
                  {username}
                </p>
                <div className="bg-primary p-1 flex items-center rounded-full shadow-md">
                  <PersonIcon className="text-white" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </nav>
    </div>
  );
};

export default Navbar