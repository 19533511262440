import React, { useEffect } from "react";
import logo from "../../assets/logo/Logo only.png";
import logo1 from "../../assets/logo/White Logo only.png";
import {
  Lock,
  Login,
  Person2,
  Visibility,
  VisibilityOff,
} from "@mui/icons-material";
import {
  Button,
  FormControl,
  IconButton,
  InputAdornment,
  TextField,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import {
  logout,
  setPassword,
  setToken,
  setUsername,
  setloggeduser,
  setusertype,
  showingLoading,
  showingPassword,
  showloggedIn,
} from "../../redux/actions/loginAction";
import axios from "axios";
import { jwtDecode } from "jwt-decode";
import { useNavigate } from "react-router-dom";

export function LoginForm() {
  const dispatch = useDispatch();
  const isLoading = useSelector((state) => state.login.isLoading);
  const username = useSelector((state) => state.login.username);
  const password = useSelector((state) => state.login.password);
  const showPassword = useSelector((state) => state.login.showPassword);
  const navigate = useNavigate();

  useEffect(() => {
    // Check if user is already logged in using stored token
    const token = localStorage.getItem("accessToken");
    if (token) {
      const decoded = jwtDecode(token);
      if (decoded.exp * 1000 > Date.now()) {
        dispatch(setToken(token));
        dispatch(showloggedIn(true));

        dispatch(setloggeduser(decoded.username));
        dispatch(setusertype(decoded.Type));
      } else {
        // Token expired, clear localStorage and logout
        navigate("/");
        localStorage.removeItem("accessToken");
        localStorage.removeItem("exp");
        dispatch(logout());
        window.scrollTo(0, 0);
        window.location.reload();
      }
    }
  }, [dispatch]);

  const handleTogglePassword = () => {
    dispatch(showingPassword(!showPassword));
  };

  const handleLogin = async (event) => {
    event.preventDefault();
    const credentials = {
      UserName: username,
      Password: password,
    };

    try {
      dispatch(showingLoading(true));
      const response = await axios.post(
        "https://api.octoberbreastcancer.com/api/Login/Admin",
        credentials
      );

      if (response.status === 200) {
        window.location.reload();
        const token = response.data.Token;
        const decoded = jwtDecode(token);

        console.log("Logged in:", decoded);

        // Dispatch the token to your Redux store
        dispatch(setToken(token));

        // Store the token securely (e.g., in localStorage)
        localStorage.setItem("accessToken", token);

        // Set an expiration time for the token (e.g., 1 hour)
        const expirationTime = decoded.exp * 1000; // Convert seconds to milliseconds
        localStorage.setItem("exp", expirationTime);
        dispatch(setloggeduser(decoded.username));
        dispatch(setusertype(decoded.Type));

        // Automatically log the user out when the token expires
        setTimeout(() => {
          dispatch(logout()); // Dispatch your logout action
          localStorage.removeItem("accessToken");
          localStorage.removeItem("exp");
        }, expirationTime - Date.now());

        dispatch(showingLoading(false));
        await dispatch(showloggedIn(true));
      } else {
        console.error("API error:", response.status, response.data);
        // Dispatch an error action if needed
        dispatch(showingLoading(false));
      }
    } catch (error) {
      console.error("Error during login:", error);
      // Dispatch an error action if needed
      dispatch(showingLoading(false));
    }
  };
  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      handleLogin(event);
    }
  };
  return (
    <div className="bg-white relative p-8 rounded-lg shadow-sm flex flex-col items-center text-center text-customBlack overflow-hidden">
      <img
        src={logo}
        className="absolute -top-16 -left-16 w-40 h-40 opacity-50"
        alt=""
      />
      <img
        src={logo}
        className="absolute -bottom-16 -right-16 -rotate-180 w-52 h-52 opacity-20"
        alt=""
      />
      <img src={logo} className="w-14 h-14 m-3" alt="" />
      <h1 className="capitalize mb-5">
        <span className="text-3xl uppercase font-bold text-primary m-2">
          Welcome to !
        </span>
        <br />
        October Breast Cancer Support{" "}
        <span className="text-primary">Organization</span>
        <br />
        <span className="text-primary font-bold tracking-wide capitalize text-xl">
          Admin <span className="font-light">panel</span>
        </span>
      </h1>
      <FormControl variant="standard">
        <TextField
          placeholder="Username"
          className="bg-transparent tracking-wider m3 text-sm rounded-xl focus:ring-0 capitalize"
          style={{
            width: 400,
            margin: 6,
          }}
          sx={{
            "& .MuiOutlinedInput-root": {
              "&.Mui-focused fieldset": {
                borderColor: "#FF7FCE", //styles the border when focused
              },
            },
          }}
          variant="outlined"
          InputProps={{
            style: {
              fontSize: 14,
              borderRadius: 12,
              borderColor: "white",
            },
            startAdornment: (
              <InputAdornment disableTypography position="start">
                <Person2 className="p-0.5 text-primary" />
              </InputAdornment>
            ),
          }}
          onChange={(e) => dispatch(setUsername(e.target.value))}
          onKeyPress={handleKeyPress}
        />
        <TextField
          placeholder="Password"
          type={showPassword ? "text" : "password"}
          className="bg-transparent tracking-wider m3 text-sm rounded-xl focus:ring-0 capitalize"
          style={{
            width: 400,
            margin: 6,
          }}
          sx={{
            "& .MuiOutlinedInput-root": {
              "&.Mui-focused fieldset": {
                borderColor: "#FF7FCE", //styles the border when focused
              },
            },
          }}
          variant="outlined"
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={handleTogglePassword} edge="end">
                  {showPassword ? (
                    <VisibilityOff className="p-0.5 text-primary" />
                  ) : (
                    <Visibility className="p-0.5 text-primary" />
                  )}
                </IconButton>
              </InputAdornment>
            ),
            style: {
              fontSize: 14,
              borderRadius: 12,
              borderColor: "white",
            },
            startAdornment: (
              <InputAdornment disableTypography position="start">
                <Lock className="p-0.5 text-primary" />
              </InputAdornment>
            ),
          }}
          onChange={(e) => dispatch(setPassword(e.target.value))}
          onKeyPress={handleKeyPress}
        />
        <Button
          component="label"
          disableElevation={true}
          variant="contained"
          style={{
            backgroundColor: "#FF7FCE",
            borderRadius: 12,
            color: "#fff",
            paddingTop: 12,
            paddingBottom: 12,
            height: 50,
            marginTop: 26,
          }}
          startIcon={!isLoading && <Login />}
          onClick={(e) => handleLogin(e)}
        >
          {isLoading ? (
            <img src={logo1} className="w-7 animate-spin" alt="" />
          ) : (
            "Login"
          )}
        </Button>
      </FormControl>
    </div>
  );
}
