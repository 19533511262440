import React from "react";
import news from "../assets/3d images/News Paper.png";
import { AddCircle, Newspaper } from "@mui/icons-material";
import { Link } from "react-router-dom";
import NewsTable from "../components/common/NewsTable";
import { useSelector } from "react-redux";

const Newsfeed = () => {
  const newsCount = useSelector((state) => state.news.allNews);
  
  return (
    <div className="mt-7 w-full">
      <div className="w-full flex items-center gap-12 relative p-10 bg-white shadow-lg rounded-xl h-44">
        <div className="p-3 w-32 bg-customBlue flex items-center justify-center rounded-xl text-white shadow-xl absolute right-10 -top-5">
          <Newspaper className="" />
        </div>
        <img src={news} className="w-40 h-fit scale-x-[-1]" alt="" />
        <div className="w-full flex justify-between items-end">
          <div className="space-y-1">
            <h1 className="font-bold text-2xl uppercase tracking-widest text-customBlack">
              News
            </h1>
            <h1 className="font-bold text-3xl uppercase">
              {newsCount.length === 0 ? "..." : newsCount.length}
            </h1>
            <p className="capitalize tracking-wider">
              Total amount of{" "}
              <span className="text-customBlue font-bold">news posted</span>
            </p>
          </div>
          <Link
            to={"/news/post"}
            className="flex items-center gap-3 text-white bg-customBlue p-3 px-9 rounded-lg tracking-widest hover:bg-blue-600 duration-200"
          >
            <AddCircle />
            Publish
          </Link>
        </div>
      </div>
      <h1 className="font-bold uppercase text-customBlack mt-10 mb-4 tracking-wide">
        News published
      </h1>
      <NewsTable />
    </div>
  );
};

export default Newsfeed;
