import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import {
  FiUser,
  FiMail,
  FiPhone,
  FiMapPin,
  FiDollarSign,
} from "react-icons/fi";
import logo from "../assets/logo/Logo only.png";
import axios from "axios";
import { fetchDonorsFailure, fetchDonorsSuccess } from "../redux/actions/donerAction";

const DonorDetail = () => {
  const { DonarId } = useParams();
  const donors = useSelector((state) => state.donors.donors);
  const user = donors.find((donor) => donor.DonarId === DonarId);
  const dispatch = useDispatch();
  //const [isLoading, setLoading] = useState(false);
  const [images, setImages] = useState();

  useEffect(() => {
    // Fetch data from the API using Axios.
    axios
      .get("https://api.octoberbreastcancer.com/api/Donar")
      .then((response) => {
        // Assuming your API response is an array of objects
        dispatch(fetchDonorsSuccess(response.data));
        console.log(response.data)
      })
      .catch((error) => {
        dispatch(fetchDonorsFailure(error.message));
      });
  }, [dispatch]);

  const fetchSlipImage = async () => {
    try {
      const donor = donors.find((donor) => donor.DonarId === DonarId);
      const imageUrl = donor && donor.Image; // Assuming the image key is Image_1

      if (imageUrl) {
        const response = await axios.get(
          `https://api.octoberbreastcancer.com/api/image/Get/Donar/${DonarId}/${imageUrl}`,
          { responseType: "blob" }
        );

        const reader = new FileReader();
        reader.onload = () => {
          const imageDataURL = reader.result;
          setImages([imageDataURL]); // Set the image URL
        };
        reader.readAsDataURL(response.data);
      }
    } catch (error) {
      console.error("Error fetching news images:", error);
    }
  };



   // const donorDetail = donors.find((donor) => donor.DonarId === DonarId);

  useEffect(() => {
    // Inside the UserDetail component
    console.log("DonarId:", DonarId);
    console.log("Donors:", donors);
    console.log("User:", user);
    console.log("Images:", images);

    fetchSlipImage();
  },[]);

  if (!user) {
    return <p>User not found</p>;
  }



  return (
    <div>
      <div className="h-full flex flex-col items-center justify-center">
        <div className="bg-white relative overflow-hidden flex items-center justify-between shadow-md rounded-lg p-8 w-full">
          <div className="space-y-6">
            <img
              src={logo}
              className="absolute -top-16 -right-14 w-40 h-40 opacity-50"
              alt=""
            />
            <img
              src={logo}
              className="absolute -bottom-20 -left-16 -rotate-90 w-32 h-32 opacity-30"
              alt=""
            />
            <div className="flex items-center mb-4">
              <FiUser className="text-primary text-4xl mr-2" />
              <h2 className="text-3xl font-bold text-gray-800">
                Doner's Detail
              </h2>
            </div>
            <div className="flex items-center space-x-2">
              <FiUser className="text-primary text-xl" />
              <span className="text-gray-700 font-semibold">First Name:</span>
              <span className="text-gray-800">{user.First_Name}</span>
            </div>
            <div className="flex items-center space-x-2">
              <FiUser className="text-primary text-xl" />
              <span className="text-gray-700 font-semibold">Last Name:</span>
              <span className="text-gray-800">{user.Last_Name}</span>
            </div>
            <div className="flex items-center space-x-2">
              <FiMail className="text-primary text-xl" />
              <span className="text-gray-700 font-semibold">Email:</span>
              <span className="text-gray-800">{user.Email}</span>
            </div>
            <div className="flex items-center space-x-2">
              <FiPhone className="text-primary text-xl" />
              <span className="text-gray-700 font-semibold">Phone:</span>
              <span className="text-gray-800">{user.Phone}</span>
            </div>
            <div className="flex items-center space-x-2">
              <FiMapPin className="text-primary text-xl" />
              <span className="text-gray-700 font-semibold">Address:</span>
              <span className="text-gray-800">{user.Address}</span>
            </div>
            <div className="flex items-center space-x-2">
              <FiDollarSign className="text-primary text-xl" />
              <span className="text-gray-700 font-semibold">Amount:</span>
              <span className="text-gray-800">
                {user.Amount}
                <span className="font-bold text-gray-600 text-sm"> ETB</span>
              </span>
            </div>
            <div className="flex items-center space-x-2">
              <FiPhone className="text-primary text-xl" />
              <span className="text-gray-700 font-semibold">
                Additional Phone:
              </span>
              <span className="text-gray-800">{user.Additional_Phone_no}</span>
            </div>
          </div>
          <div className="w-2/4 h-56">
            <img className="object-cover w-full h-full" src={images} alt="" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default DonorDetail;
