import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import logo from "../assets/logo/Logo only.png";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/navigation";
import "swiper/css/thumbs";
import { FreeMode, Thumbs, Autoplay, Navigation } from "swiper/modules";
import { AddAPhoto, Delete, UploadFile } from "@mui/icons-material";
// import slip from "../assets/Slip.jpg";
import {
  CalendarMonth,
  Newspaper,
  NewspaperTwoTone,
  Person2,
  TextFields,
} from "@mui/icons-material";
import { Button } from "@mui/material";
import axios from "axios";
import { fetchBlogSuccess } from "../redux/actions/blogAction";

const BlogDetail = () => {
  const { BlogId } = useParams();
  const dispatch = useDispatch();
  const allBlog = useSelector((state) => state.blog.allBlogs);
  const token = useSelector((state) => state.login.accessToken);
  const [isLoading, setLoading] = useState(false);
  const [images, setImages] = useState([]);

  const fetchBlog = () => {
    axios
      .get("https://api.octoberbreastcancer.com/api/Blog")
      .then((response) => {
        dispatch(fetchBlogSuccess(response.data));
      })
      .catch((error) => {
        console.log(error.message);
      });
  };

  const fetchBlogImage = async () => {
    try {
      const news = allBlog.find((news) => news.BlogId === BlogId);
      const imageUrls = [];

      for (let i = 1; i <= 5; i++) {
        const imageUrl = news[`Image_${i}`];
        if (imageUrl) {
          const response = await axios.get(
            `https://api.octoberbreastcancer.com/api/image/Get/Blog/${BlogId}/${imageUrl}`,
            { responseType: "blob" }
          );
          const reader = new FileReader();
          reader.onload = () => {
            imageUrls.push(reader.result);
            if (imageUrls.length === 1) {
              setImages(imageUrls); // Set the image URLs array
            }
          };
          reader.readAsDataURL(response.data);
        }
      }
    } catch (error) {
      console.error("Error fetching news images:", error);
    }
  };

  const theBlog = allBlog.find((blog) => blog.BlogId === BlogId);

  useEffect(() => {
    fetchBlog();
  });

  useEffect(() => {
    if (theBlog) {
      fetchBlogImage();
    }
  });

  const deleteNews = async () => {
    try {
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };
      setLoading(true);

      await axios.delete(
        `https://api.octoberbreastcancer.com/api/News/Delete/${BlogId}`,
        config
      );
      fetchBlog();
      console.log("News deleted successfully");
      setLoading(false);
    } catch (error) {
      console.error("Error deleting news:", error);
      setLoading(false);
    }
  };

  if (!theBlog) {
    return (
      <div className="w-full h-[30rem] flex items-center justify-center font-bold text-slate-600 text-lg capitalize">
        <div className="flex flex-col items-center">
          <NewspaperTwoTone className="text-primary" />
          News not found !
        </div>
      </div>
    );
  }

  return (
    <div>
      <div className="h-full flex flex-col items-center justify-center">
        <div className="bg-white relative overflow-hidden flex items-center justify-between shadow-md rounded-lg p-8 w-full">
          <div className="space-y-6">
            <img
              src={logo}
              className="absolute -top-16 -right-14 w-40 h-40 opacity-50"
              alt=""
            />
            <img
              src={logo}
              className="absolute -bottom-20 -left-16 -rotate-90 w-32 h-32 opacity-30"
              alt=""
            />
            <div className="flex uppercase items-center mb-4">
              <Newspaper className="text-primary text-4xl mr-2" />
              <span className="text-gray-700 font-semibold mr-3">Title: </span>
              <h2 className="text-3xl font-bold text-gray-800">
                {theBlog.Title}
              </h2>
            </div>

            <div className="flex items-start space-x-2">
              <TextFields className="text-primary text-xl" />
              <span className="text-gray-700 font-semibold">Article:</span>
              <h1 className="w-80">{theBlog.Body}</h1>
            </div>
            <div className="flex items-center space-x-2">
              <Person2 className="text-primary text-xl" />
              <span className="text-gray-700 font-semibold">Publisher:</span>
              <span className="text-gray-800">{theBlog.Author}</span>
            </div>
            <div className="flex items-center space-x-2">
              <CalendarMonth className="text-primary text-xl" />
              <span className="text-gray-700 font-semibold">Date:</span>
              <span className="text-gray-800">{theBlog.createdAt}</span>
            </div>
            <div className="flex items-center space-x-2">
              <Button
                className="text-gray-700 font-bold"
                style={{
                  backgroundColor: "#FF7FCE",
                  borderRadius: 8,
                  color: "#fff",
                  paddingTop: 12,
                  paddingBottom: 12,
                  height: 40,
                }}
                startIcon={<Delete className="text-white text-xl" />}
                onClick={() => deleteNews()}
                disabled={isLoading}
              >
                {!isLoading ? "Remove Blog" : "Deleting Blog..."}
              </Button>
            </div>
          </div>
          <div className="w-2/4 h-full">
                {images.map((image, index) => (
                    <img
                      className="w-full h-full rounded-lg object-contain"
                      src={image}
                      alt=""
                    />
                ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default BlogDetail;
