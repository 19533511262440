//import { SmallCard } from '../components/feature/SmallCard';
import { Person } from '@mui/icons-material'
//import { Button } from '@mui/material';
import React from 'react'
import { useSelector } from "react-redux";
//import AdminsTable from '../components/common/AdminsTable';
//import { showAdminModule } from '../redux/actions/adminActions';

const Account = () => {
  const username=useSelector(state=>state.login.loggeduser)
  const usertype = useSelector((state) => state.login.userType);
  //const showAdmin = useSelector((state) => state.admin.showAdminModule);
  //const dispatch = useDispatch();

// const handleSubmit = () => {
//   console.log("Button clicked!"); // Add this line to check if the function is called
//   window.scrollTo(0, 0);
//   dispatch(showAdminModule(true));
// };

  return (
    <div className="p-8 w-full h-full flex items-center justify-center flex-col gap-2">
      <span className="w-20 h-20 bg-primary flex items-center justify-center text-white rounded-full">
        <Person />
      </span>
      {/* <Button
        className="w-fit flex items-center"
        sx={{
          background: "#FF7FCE",
          color: "#fff",
        }}
      >
        Edit Profile
        <Edit className="p-1" />
      </Button> */}
      <h1 className="font-bold text-xl text-customBlack">{username}</h1>
      <p className="font-bold text-sm text-primary uppercase tracking-wider">
        {usertype}
      </p>
      {/* <div className="mt-8 flex gap-9 items-center">
        <SmallCard
          icon={<Newspaper />}
          name={"News"}
          subname={" you published"}
          amount={newsCount.length}
          type={"news"}
        />
        <SmallCard
          icon={<PostAdd />}
          name={"Blogs"}
          subname={" you posted"}
          amount={blogCount.length}
          type={"blog"}
        />
      </div> */}
      {usertype === "SuperAdmin" && (
        <div>
          {/* <div className="text-lg font-bold text-slate-800 my-3 flex items-center w-[97%] justify-between">
            <div className="flex items-center gap-3">
              <Person2Rounded />
              Administrators
            </div>
            <Button
              onClick={() => handleSubmit()}
              className="flex items-center gap-1"
            >
              Add Admin <AddCircle />
            </Button>
          </div>
          <AdminsTable /> */}
        </div>
      )}
    </div>
  );
}

export default Account