import { combineReducers } from "@reduxjs/toolkit";
import { imageReducer } from "./reducers/imageReducer";
import { loginReducer } from "./reducers/loginReducer";
import donorsReducer from "./reducers/donerReducer";
import { adminReducer } from "./reducers/adminReducer";
import { newsReducer } from "./reducers/newsReducer";
import { blogReducer } from "./reducers/blogReducer";
import { memberReducer } from "./reducers/memberReducer";

const initialState = {
    start:'Started',
    isExpanded:true
}

const sidePanelReducer = (state = initialState, action) => {
  switch (action.type) {
    case "TOGGLE_SIDE_PANEL":
      return { ...state, isExpanded: !state.isExpanded };
    default:
      return state;
  }
};


export const rootReducer = combineReducers({
  started: sidePanelReducer,
  panel: sidePanelReducer,
  images: imageReducer,
  login: loginReducer,
  donors: donorsReducer,
  members:memberReducer,
  admin: adminReducer,
  news: newsReducer,
  blog: blogReducer,
});