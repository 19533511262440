import { NewsPostForm } from "../components/common/NewsPostForm";
import newspaper from "../assets/3d images/News Paper.png";
import React, { useRef, useState } from "react";
import { useDispatch } from "react-redux";
import {
  deleteNewsImage,
} from "../redux/actions/imageActions";
import { Swiper, SwiperSlide } from "swiper/react";
import { FreeMode, Thumbs, Autoplay, Navigation } from "swiper/modules";
import { AddAPhoto, Delete, UploadFile } from "@mui/icons-material";

const NewsPost = () => {
  const [previewUrls, setPreviewUrls] = useState([]);
  const thumbsSwiper = useRef(null);
  const dispatch = useDispatch();
  const [fileObjects, setFileObjects] = useState([]);

  const handleFileChange = (event) => {
    const files = Array.from(event.target.files);
    const newPreviewUrls = [];
    files.forEach((file, index) => {
      const reader = new FileReader();
      reader.onloadend = () => {
        newPreviewUrls.push(reader.result);
        if (newPreviewUrls.length === files.length) {
          setPreviewUrls((prevUrls) => [...prevUrls, ...newPreviewUrls]);
        }
      };
      reader.readAsDataURL(file);
    });

    // Store the actual File objects
    setFileObjects((prevFileObjects) => [...prevFileObjects, ...files]);
  };


  const handleDeleteImage = (e, index) => {
    e.preventDefault()
    setFileObjects((prevFileObjects) =>
      prevFileObjects.filter((_, i) => i !== index)
    );
    setPreviewUrls((prevUrls) => prevUrls.filter((_, i) => i !== index));
    dispatch(deleteNewsImage(index));
  };

  return (
    <div className="space-y-8 my-9">
      <div className="flex justify-between  items-center">
        <div className="flex -z-0 flex-col justify-start items-start w-full gap-3">
          <label className="cursor-pointer w-44">
            <label
              htmlFor="imageUpload"
              className="cursor-pointer w-fit inline-flex gap-4 items-center mb-4 px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-customBlue hover:bg-blue-600 duration-200"
            >
              <UploadFile /> Upload Images
              <input
                id="imageUpload"
                type="file"
                multiple
                accept="image/*"
                onChange={handleFileChange}
                className="hidden w-fit"
              />
            </label>
            {}{" "}
            {previewUrls.length > 0 ? (
              <Swiper
                loop={true}
                autoplay={true}
                spaceBetween={10}
                navigation
                thumbs={{ swiper: thumbsSwiper.current }}
                modules={[FreeMode, Thumbs, Autoplay, Navigation]}
                className="w-[31rem] relative h-72 rounded-lg bg-white"
              >
                {previewUrls.map((image, index) => (
                  <SwiperSlide key={index}>
                    <button
                      className="absolute text-sm flex items-center bg-red-500 px-3 py-1 text-white m-2 rounded-lg shadow-lg hover:bg-red-700"
                      onClick={(e) => handleDeleteImage(e, index)}
                    >
                      <Delete className="p-1" /> Delete
                    </button>
                    <img
                      className="w-full h-full rounded-lg object-contain"
                      src={image}
                      alt=""
                    />
                  </SwiperSlide>
                ))}
              </Swiper>
            ) : (
              <div className="w-full h-72 flex flex-col bg-white px-56 items-center justify-center  text-slate-300 gap-2 tracking-wide rounded-lg">
                <AddAPhoto />
                Upload
                <input
                  id="imageUpload"
                  type="file"
                  accept="image/*"
                  className="sr-only"
                  onChange={handleFileChange}
                />
              </div>
            )}
          </label>
        </div>
        <div className="w-3/4 h-40 bg-white rounded-lg relative">
          <div className="flex justify-between p-8 items-center">
            {" "}
            <h1 className="font-bold text-xl uppercase text-right tracking-widest text-customBlue">
              Publish <br />
              <span className="text-5xl tracking-wider font-light text-customBlack">
                News
              </span>
            </h1>
            <img
              className="w-44 absolute right-6 -top-11"
              src={newspaper}
              alt=""
            />
          </div>
        </div>
      </div>
      <NewsPostForm fileObjects={fileObjects} />
    </div>
  );
};

export default NewsPost;
