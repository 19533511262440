import React, { useEffect, useState } from "react";
import { DataGrid } from "@mui/x-data-grid";
import { Box, Button } from "@mui/material";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import {
  fetchNewsFailure,
  fetchNewsSuccess,
} from "../../redux/actions/newsAction";

const columns = [
  {
    field: "NewsId",
    headerName: "News ID",
    width: 90,
    renderCell: (params) => (
      <Link to={`/news/${params.row.NewsId}`}>{params.row.id}</Link>
    ),
  },
  {
    field: "Title",
    headerName: "Title",
    width: 150,
    editable: true,
    renderCell: (params) => (
      <Link to={`/news/${params.row.NewsId}`}>{params.row.Title}</Link>
    ),
  },
  {
    field: "Author",
    headerName: "Author",
    width: 400,
    editable: true,
    renderCell: (params) => (
      <Link to={`/news/${params.row.NewsId}`}>{params.row.Author}</Link>
    ),
  },
  {
    field: "createdAt",
    headerName: "Date",
    width: 200,
    editable: true,
    renderCell: (params) => (
      <Link to={`/news/${params.row.NewsId}`}>{params.row.createdAt}</Link>
    ),
  },
  {
    field: "delete",
    headerName: "Delete",
    width: 100,
    renderCell: (params) => <DeleteButton newsId={params.row.NewsId} />,
  },
];

const DeleteButton = ({ newsId }) => {
  const dispatch = useDispatch();
  const token = useSelector((state) => state.login.accessToken);
  const [isLoading, setLoading] = useState(false);

   const fetchNews = () => {
     axios
       .get("https://api.octoberbreastcancer.com/api/News")
       .then((response) => {
         dispatch(fetchNewsSuccess(response.data));
       })
       .catch((error) => {
         console.log(error.message);
       });
   };

  const handleDelete = async () => {
    try {
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };
      setLoading(true);

      await axios.delete(
        `https://api.octoberbreastcancer.com/api/News/Delete/${newsId}`,
        config
      );
      fetchNews();
      console.log("News deleted successfully");
      setLoading(false);
    } catch (error) {
      console.error("Error deleting news:", error);
      setLoading(false);
    }
  };

  return (
    <Button
      variant="contained"
      color="error"
      disableElevation
      onClick={handleDelete}
      sx={{width:100}}
    >
      {isLoading ? "..." : "Delete"}
    </Button>
  );
};

const NewsTable = () => {
  const dispatch = useDispatch();
  const news = useSelector((state) => state.news.allNews);

  useEffect(() => {
    // Fetch data from the API using Axios.
    axios
      .get("https://api.octoberbreastcancer.com/api/News")
      .then((response) => {
        // Assuming your API response is an array of objects
        console.log(response.data);
        dispatch(fetchNewsSuccess(response.data));
      })
      .catch((error) => {
        dispatch(fetchNewsFailure(error.message));
        console.log(error.message);
      });
  }, [dispatch]);

  // Add a check for undefined or empty news
  if (!news || news.length === 0) {
    return <div>Loading...</div>;
  }

  return (
    <Box>
      <DataGrid
        sx={{ marginRight: 2, background: "#fff" }}
        rows={news}
        columns={columns}
        initialState={{
          pagination: {
            paginationModel: {
              pageSize: 5,
            },
          },
        }}
        pageSizeOptions={[5]}
        checkboxSelection
        disableRowSelectionOnClick
      />
    </Box>
  );
};

export default NewsTable;
