import React, { useEffect, useState } from "react";
import { DataGrid } from "@mui/x-data-grid";
import { Box, Button } from "@mui/material";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { fetchMembersFailure, fetchMembersSuccess } from "../../redux/actions/memberAction";

const columns = [
  {
    field: "id",
    headerName: "Member ID",
    width: 90,
    renderCell: (params) => (
      <Link to={`/memberDetail/${params.row.MembersId}`}>{params.row.id}</Link>
    ),
  },
  {
    field: "first_name",
    headerName: "First name",
    width: 150,
    editable: true,
    renderCell: (params) => (
      <Link to={`/memberDetail/${params.row.MembersId}`}>
        {params.row.first_name}
      </Link>
    ),
  },
  {
    field: "last_name",
    headerName: "Last name",
    width: 150,
    editable: true,
    renderCell: (params) => (
      <Link to={`/memberDetail/${params.row.MembersId}`}>
        {params.row.last_name}
      </Link>
    ),
  },
  {
    field: "gender",
    headerName: "Sex",
    renderCell: (params) => (
      <Link to={`/memberDetail/${params.row.MembersId}`}>
        {params.row.gender}
      </Link>
    ),
  },
  {
    field: "phone_number",
    headerName: "Phone-number",
    width: 180,
    renderCell: (params) => (
      <Link to={`/memberDetail/${params.row.MembersId}`}>
        {params.row.phone_number}
      </Link>
    ),
  },
  {
    field: "qualification",
    headerName: "Qualification",
    width: 150,
    renderCell: (params) => (
      <Link to={`/memberDetail/${params.row.MembersId}`}>
        {params.row.qualification}
      </Link>
    ),
  },
  {
    field: "delete",
    headerName: "Delete",
    width: 100,
    renderCell: (params) => <DeleteButton MembersId={params.row.MembersId} />,
  },
];


const DeleteButton = ({ MembersId }) => {
  const dispatch = useDispatch();
  const token = useSelector((state) => state.login.accessToken);
  const [isLoading, setLoading] = useState(false);

  const fetchNews = () => {
    axios
      .get("https://api.octoberbreastcancer.com/api/Members")
      .then((response) => {
        dispatch(fetchMembersSuccess(response.data));
      })
      .catch((error) => {
        console.log(error.message);
      });
  };

  const handleDelete = async () => {
    try {
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };
      setLoading(true);

      await axios.delete(
        `https://api.octoberbreastcancer.com/api/Members/Delete/${MembersId}`,
        config
      );
      fetchNews();
      console.log("Members deleted successfully");
      setLoading(false);
    } catch (error) {
      console.error("Error deleting news:", error);
      setLoading(false);
    }
  };

  return (
    <Button
      variant="contained"
      color="error"
      disableElevation
      onClick={handleDelete}
      sx={{ width: 100 }}
    >
      {isLoading ? "..." : "Delete"}
    </Button>
  );
};

const MembershipTable = () => {
  const dispatch = useDispatch();
  const members = useSelector((state) => state.members.members);

  useEffect(() => {
    // Fetch data from the API using Axios.
    axios
      .get("https://api.octoberbreastcancer.com/api/Members")
      .then((response) => {
        // Assuming your API response is an array of objects
        dispatch(fetchMembersSuccess(response.data));
      })
      .catch((error) => {
        dispatch(fetchMembersFailure(error.message));
      });
  }, [dispatch]);
  return (
    <Box>
      <DataGrid
        sx={{ marginRight: 2, background: "#fff" }}
        rows={members}
        columns={columns}
        initialState={{
          pagination: {
            paginationModel: {
              pageSize: 5,
            },
          },
        }}
        pageSizeOptions={[5]}
        checkboxSelection
        disableRowSelectionOnClick
      />
    </Box>
  );
};

export default MembershipTable;
