import React, { useEffect, useState } from "react";
import { DataGrid } from "@mui/x-data-grid";
import { Box, Button } from "@mui/material";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import {
  fetchBlogFailure,
  fetchBlogSuccess,
} from "../../redux/actions/blogAction";

const columns = [
  {
    field: "BlogId",
    headerName: "Blog ID",
    width: 90,
    renderCell: (params) => (
      <Link to={`/blogs/${params.row.BlogId}`}>{params.row.id}</Link>
    ),
  },
  {
    field: "Title",
    headerName: "Title",
    width: 220,
    renderCell: (params) => (
      <Link to={`/blogs/${params.row.BlogId}`}>{params.row.Title}</Link>
    ),
  },
  {
    field: "Author",
    headerName: "Author",
    width: 280,
    renderCell: (params) => (
      <Link to={`/blogs/${params.row.NewsId}`}>{params.row.Author}</Link>
    ),
  },
  {
    field: "createdAt",
    headerName: "Date",
    width: 200,
    renderCell: (params) => (
      <Link to={`/blogs/${params.row.NewsId}`}>{params.row.createdAt}</Link>
    ),
  },

  {
    field: "delete",
    headerName: "Delete",
    width: 100,
    renderCell: (params) => <DeleteButton blogId={params.row.BlogId} />,
  },
];

const DeleteButton = ({ blogId }) => {
  const dispatch = useDispatch();
  const token = useSelector((state) => state.login.accessToken);
  const [isLoading, setLoading] = useState(false);

  const fetchBlogs = () => {
    axios
      .get("https://api.octoberbreastcancer.com/api/Blog")
      .then((response) => {
         dispatch(fetchBlogSuccess(response.data));
      })
      .catch((error) => {
        console.log(error.message);
      });
  };

  const handleDelete = async () => {
    try {
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };
      setLoading(true);

      await axios.delete(
        `https://api.octoberbreastcancer.com/api/Blog/Delete/${blogId}`,
        config
      );
      fetchBlogs();
      console.log("Blog deleted successfully");
      setLoading(false);
    } catch (error) {
      console.error("Error deleting blog:", error);
      setLoading(false);
    }
  };

  return (
    <Button
      variant="contained"
      color="error"
      disableElevation
      onClick={handleDelete}
      sx={{ width: 100 }}
    >
      {isLoading ? "..." : "Delete"}
    </Button>
  );
};

const BlogsTable = () => {
  const dispatch = useDispatch();
  const blogs = useSelector((state) => state.blog.allBlogs);

  useEffect(() => {
    // Fetch data from the API using Axios.
    axios
      .get("https://api.octoberbreastcancer.com/api/Blog")
      .then((response) => {
        // Assuming your API response is an array of objects
        console.log(response.data);
        dispatch(fetchBlogSuccess(response.data));
      })
      .catch((error) => {
        dispatch(fetchBlogFailure(error.message));
        console.log(error.message);
      });
  }, [dispatch]);

  // Add a check for undefined or empty news
  if (!blogs || blogs.length === 0) {
    return <div>Loading...</div>;
  }

  return (
    <Box>
      <DataGrid
        sx={{ marginRight: 2, background: "#fff" }}
        rows={blogs}
        columns={columns}
        initialState={{
          pagination: {
            paginationModel: {
              pageSize: 5,
            },
          },
        }}
        pageSizeOptions={[5]}
        checkboxSelection
        disableRowSelectionOnClick
      />
    </Box>
  );
};

export default BlogsTable;
