import React, { useState } from "react";
import { Button, FormControl, InputAdornment, TextField } from "@mui/material";
import {
  AddCircle,
  Article,
  CalendarToday,
  Newspaper,
  Person,
} from "@mui/icons-material";
import logo1 from "../../assets/logo/White Logo only.png";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import {
  isError,
  isLoading,
  isSuccess,
  setNewsBody,
  setNewsDate,
  setNewsPublisher,
  setNewsTitle,
} from "../../redux/actions/newsAction";

export function NewsPostForm({ fileObjects }) {
  const newsTitle = useSelector((state) => state.news.newsTitle);
  const newsBody = useSelector((state) => state.news.newsBody);
  const newsPublisher = useSelector((state) => state.news.newsPublisher);
  const newsDate = useSelector((state) => state.news.newsDate);
  const loading = useSelector((state) => state.news.loading);
  const token = useSelector((state) => state.login.accessToken);

  const dispatch = useDispatch();
  const [errors, setErrors] = useState({
    title: false,
    body: false,
    publisher: false,
    date: false,
    fileObjects: false,
  });

  const handleSubmit = async (event) => {
    event.preventDefault();

    // Validate input fields
    const validationErrors = {
      title: !newsTitle.trim(),
      body: !newsBody.trim(),
      publisher: !newsPublisher.trim(),
      date: !newsDate,
      fileObjects: fileObjects.length === 0,
    };

    // Display errors for empty fields
    setErrors(validationErrors);

    // Check if any field is empty
    if (Object.values(validationErrors).some((error) => error)) {
      return;
    }

    // Scroll to top and start loading
    window.scrollTo(0, 0);
    dispatch(isLoading(true));

    try {
      // Submit news data
      const newsData = {
        Title: newsTitle,
        Body: newsBody,
        Author: newsPublisher,
        Date: newsDate,
      };
      const response = await axios.post(
        "https://api.octoberbreastcancer.com/api/News",
        newsData
      );

      if (response.status === 200) {
        console.log("News submitted successfully:", response.data);

        // Upload images
        if (fileObjects.length === 0) {
          alert("Please select at least one file to upload");
          return;
        }

        const formDataArray = fileObjects.map((file, index) => {
          const formData = new FormData();
          formData.append("image", file);
          return formData;
        });

        const uploadPromises = formDataArray.map((formData, index) => {
          const apiUrl = `https://api.octoberbreastcancer.com/api/Upload/image/News/${
            response.data
          }/${index + 1}`;
          return axios.post(apiUrl, formData, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          });
        });

        Promise.all(uploadPromises)
          .then((responses) => {
            console.log("All images uploaded successfully:", responses);
            dispatch(isSuccess("News published successfully"));
            dispatch(isLoading(false));
          })
          .catch(async (error) => {
            console.error("Error uploading images:", error);
            dispatch(isError("There was an error uploading images"));
            dispatch(isLoading(false));
            // Delete news if image upload fails
            try {
              const config = {
                headers: {
                  Authorization: `Bearer ${token}`,
                },
              };

              await axios.delete(
                `https://api.octoberbreastcancer.com/api/News/Delete/${response.data}`,
                config
              );
              console.log("News deleted successfully");
            } catch (deleteError) {
              console.error("Error deleting news:", deleteError);
            }
          });
      }
    } catch (error) {
      console.error("Error sending news:", error);
      dispatch(isError("There was an error submitting news"));
      dispatch(isLoading(false));
    }
  };


  return (
    <FormControl variant="standard">
      <div className="flex  w-full justify-between gap-8">
        <div className="flex flex-col gap-5">
          <TextField
            placeholder="ARTICLE TITLE"
            className="bg-white text-sm rounded-xl focus:ring-0 capitalize"
            style={{
              width: 500,
            }}
            sx={{
              "& .MuiOutlinedInput-root": {
                "&.Mui-focused fieldset": {
                  borderColor: errors.title ? "red" : "#7FB2FF", //styles the border when focused
                },
              },
            }}
            variant="outlined"
            error={errors.title}
            helperText={errors.title && "Title is required"}
            InputProps={{
              style: {
                fontSize: 14,
                borderRadius: 12,
                borderWidth: "1px",
                borderColor: "white",
              },
              startAdornment: (
                <InputAdornment disableTypography position="start">
                  <Newspaper className="p-0.5 text-customBlue" />
                </InputAdornment>
              ),
            }}
            onChange={(e) => dispatch(setNewsTitle(e.target.value))}
          />
          <TextField
            placeholder="ARTICLE"
            className="bg-white text-sm rounded-xl focus:ring-0 capitalize"
            style={{
              width: 500,
            }}
            sx={{
              "& .MuiOutlinedInput-root": {
                "&.Mui-focused fieldset": {
                  borderColor: errors.body ? "red" : "#7FB2FF", //styles the border when focused
                },
              },
            }}
            variant="outlined"
            error={errors.body}
            helperText={errors.body && "Body is required"}
            multiline
            rows={8}
            InputProps={{
              style: {
                fontSize: 14,
                borderRadius: 12,
                borderWidth: "1px",
                borderColor: "white",
              },
              startAdornment: (
                <InputAdornment
                  disableTypography
                  position="start"
                  style={{
                    alignSelf: "flex-start",
                    marginTop: "8px",
                  }}
                >
                  <Article className="p-0.5 text-customBlue" />
                </InputAdornment>
              ),
            }}
            onChange={(e) => dispatch(setNewsBody(e.target.value))}
          />
        </div>
        <div className="flex flex-col justify-between">
          <div className="flex flex-col gap-5">
            <TextField
              placeholder="PUBLISHED BY"
              className="bg-white text-sm rounded-xl focus:ring-0 capitalize"
              style={{
                width: 500,
              }}
              sx={{
                "& .MuiOutlinedInput-root": {
                  "&.Mui-focused fieldset": {
                    borderColor: errors.publisher ? "red" : "#7FB2FF", //styles the border when focused
                  },
                },
              }}
              variant="outlined"
              error={errors.publisher}
              helperText={errors.publisher && "Publisher is required"}
              InputProps={{
                style: {
                  fontSize: 14,
                  borderRadius: 12,
                  borderWidth: "1px",
                  borderColor: "white",
                },
                startAdornment: (
                  <InputAdornment disableTypography position="start">
                    <Person className="p-0.5 text-customBlue" />
                  </InputAdornment>
                ),
              }}
              onChange={(e) => dispatch(setNewsPublisher(e.target.value))}
            />
            <TextField
              placeholder="PUBLISHED DATE"
              type="date"
              className="bg-white text-sm rounded-xl focus:ring-0 capitalize"
              style={{
                width: 500,
              }}
              sx={{
                "& .MuiOutlinedInput-root": {
                  "&.Mui-focused fieldset": {
                    borderColor: errors.date ? "red" : "#7FB2FF", //styles the border when focused
                  },
                },
              }}
              variant="outlined"
              error={errors.date}
              helperText={errors.date && "Date is required"}
              InputProps={{
                style: {
                  fontSize: 14,
                  borderRadius: 12,
                  borderWidth: "1px",
                  borderColor: "white",
                },
                startAdornment: (
                  <InputAdornment disableTypography position="start">
                    <CalendarToday className="p-0.5 text-customBlue" />
                  </InputAdornment>
                ),
              }}
              onChange={(e) => dispatch(setNewsDate(e.target.value))}
            />
          </div>
          <Button
            component="label"
            variant="contained"
            style={{
              backgroundColor: "#7FB2FF",
              borderRadius: 8,
              color: "#fff",
              paddingTop: 10,
              paddingBottom: 10,
            }}
            startIcon={!loading && <AddCircle />}
            disableElevation={true}
            onClick={(e) => handleSubmit(e)}
          >
            {loading ? (
              <img src={logo1} className="w-7 animate-spin z-50" alt="" />
            ) : (
              "Post News"
            )}
          </Button>
        </div>
      </div>
      {errors.fileObjects && (
        <div className="text-red-500">
          Please select at least one image to upload
        </div>
      )}
    </FormControl>
  );
}
