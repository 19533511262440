import { useSelector } from "react-redux";
import React, { useEffect } from "react";
import { LoginForm } from "../components/common/LoginForm";

const Login = () => {
  const isLogged = useSelector((state) => state.login.isLogged);

  useEffect(() => {
    if (isLogged) {
      document.body.style.overflow = "scroll";
    } else {
      document.body.style.overflow = "hidden";
    }
  }, [isLogged]);

  return (
    !isLogged && (
      <div className="w-full absolute h-screen bg-black z-50 bg-opacity-30 text-white flex items-center justify-center">
        <LoginForm />
      </div>
    )
  );
};

export default Login;
