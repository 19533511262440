const initialState = {
  newsImage: [],
  newsTitle: "",
  newsBody: "",
  newsPublisher: "",
  newsDate: "",
  success: "",
  error: "",
  loading: false,
  allNews:[]
};

export const newsReducer = (state = initialState,  action ) => {
  switch (action.type) {
    case "FETCH_NEWS_SUCCESS":
      return {
        ...state,
        allNews: action.payload,
        error: "",
      };
    case "FETCH_NEWS_FAILURE":
      return {
        ...state,
        error: action.payload,
      };
    case "SET_NEWS_TITLE":
      return {
        ...state,
        newsTitle: action.payload,
      };
    case "SET_NEWS_BODY":
      return {
        ...state,
        newsBody: action.payload,
      };
    case "SET_NEWS_PUBLISHER":
      return {
        ...state,
        newsPublisher: action.payload,
      };
    case "SET_NEWS_DATE":
      return {
        ...state,
        newsDate: action.payload,
      };
    case "NEWS_LOADING_STATE":
      return {
        ...state,
        loading: action.payload,
      };
    case "NEWS_ERROR_STATE":
      return {
        ...state,
        error: action.payload,
      };
    case "NEWS_SUCCESS_STATE":
      return {
        ...state,
        success: action.payload,
      };

    default:
      return state;
  }
}
