// reducers.js
import { FETCH_DONORS_SUCCESS, FETCH_DONORS_FAILURE } from "../../redux/actions/donerAction";

const initialState = {
  donors: [],
  error: null,
};

const donorsReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_DONORS_SUCCESS:
      return {
        ...state,
        donors: action.payload,
        error: null,
      };
    case FETCH_DONORS_FAILURE:
      return {
        ...state,
        error: action.payload,
      };
    default:
      return state;
  }
};

export default donorsReducer;
