
export const fetchMembersSuccess = (members) => ({
  type: "FETCH_MEMBERS_SUCCESS",
  payload: members,
});

export const fetchMembersFailure = (error) => ({
  type: "FETCH_MEMBERS_FAILURE",
  payload: error,
});
