
function LinkButton({ placeholder, icon,activeRoute,isExpanded }) {
  return (
    <div>
      <button
        className={`text-end hover:bg-primaryLight ${
          activeRoute ? "bg-primary text-white" : "bg-primaryDark"
        } duration-150 p-4 px-8 text-customBlack font-normal uppercase text-xs tracking-[0.1rem] w-full`}
      >
        <div
          className={`flex items-center w-full ${
            isExpanded ? "justify-between" : "justify-center"
          }`}
        >
          <div>
            {" "}
            {icon}
          </div>
          <div>{placeholder}</div>
        </div>
      </button>
    </div>
  );
}

export default LinkButton;
