import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import breastLogo from "../../assets/logo/Logo only.png";
import logo from "../../assets/logo/Logo only.png";

const LoadingModule = () => {
  const loading = useSelector((state) => state.news.loading);

  useEffect(() => {
    if (loading) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "scroll";
    }
  }, [loading]);

  return (
    loading && (
      <div className="fixed inset-0 z-[100] flex items-center justify-center bg-black bg-opacity-20">
        <div className="bg-white overflow-hidden relative p-8 h-auto rounded-lg w-11/12 sm:w-4/5 md:w-2/3 lg:w-1/2 xl:w-1/3 flex items-center flex-col gap-4 justify-center">
          <img
            src={logo}
            className="absolute -top-12 -left-12 w-24 h-24 sm:w-32 sm:h-32 opacity-50"
            alt=""
          />
          <img
            src={logo}
            className="absolute -bottom-16 -right-16 -rotate-180 w-32 h-32 sm:w-44 sm:h-44 opacity-20"
            alt=""
          />

          <img src={breastLogo} className="w-12 sm:w-14 animate-spin" alt="" />
          <div>
            <h1 className="text-center tracking-wide font-black text-customBlack uppercase text-lg sm:text-xl">
              The news is being <span className="text-primary">published!</span>
            </h1>
            <p className="text-xs sm:text-sm text-center tracking-widest text-customBlack">
              Please wait...
            </p>
          </div>
        </div>
      </div>
    )
  );
};

export default LoadingModule;
