const initialState = {
  selectedFiles: [],
};

export const imageReducer = (state = initialState, action) => {
  switch (action.type) {
    case "SELECT_IMAGES":
      return {
        ...state,
        selectedFiles: [...state.selectedFiles, ...action.payload],
      };
    case "RESET_IMAGES":
      return initialState;
    default:
      return state;
  }
};

export default imageReducer;
