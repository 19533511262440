import React from 'react'
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import logo from '../assets/logo/Logo with text.png'
import LinkButton from '../components/common/LinkButton';
import DashboardIcon from "@mui/icons-material/Dashboard";
import VolunteerActivismIcon from "@mui/icons-material/VolunteerActivism";
import RememberMeIcon from "@mui/icons-material/RememberMe";
import NewspaperIcon from "@mui/icons-material/Newspaper";
import BookIcon from "@mui/icons-material/Book";
import PersonIcon from "@mui/icons-material/Person";
import LogoutIcon from "@mui/icons-material/Logout";
import { logout, setloggeduser } from '../redux/actions/loginAction';

const SideBar = () => {
  const location = useLocation();
  const isExpanded = useSelector((state) => state.panel.isExpanded);
  const dispatch = useDispatch() 
  const navigate=useNavigate()
  
  const handleLogout = () => {
   window.scrollTo(0, 0);
   try {
     // Dispatch a logout action to reset the Redux state
     navigate("/");
     dispatch(logout());

     // Remove the access token and expiration time from localStorage
     localStorage.removeItem("accessToken");
     localStorage.removeItem("exp");
     dispatch(setloggeduser(""));
     window.location.reload()
     // Redirect the user to the login page or any other appropriate action
     // Example: history.push('/login');
   } catch (error) {
     console.error("Error during logout:", error);
     // Dispatch an error action or handle the error appropriately
   }
 };


  return (
    <aside
      className={`fixed overflow-scroll z-40 scrollbar-thin scrollbar-thumb-primary scrollbar-track-gray-100 h-screen ${
        isExpanded ? "w-1/6" : "w-20"
      } shadow-xl bg-white transition-all duration-300`}
    >
      <div className="h-full w-full flex flex-col items-center gap-5">
        <div className="flex items-center w-1/2 mt-5 justify-center">
          <img className="object-cover" src={logo} alt="" />
        </div>
        <div className="w-full h-0.5 my-4 bg-primary" />
        <div className="flex w-full flex-col justify-between h-full">
          <div className="flex flex-col gap-5">
            <Link to={"/"}>
              <LinkButton
                icon={<DashboardIcon className="w-7 h-7" />}
                activeRoute={location.pathname === "/"}
                placeholder={isExpanded ? "Dashboard" : null}
                isExpanded={isExpanded}
              />
            </Link>
            <Link to={"/donations"}>
              <LinkButton
                icon={<VolunteerActivismIcon className="w-7 h-7" />}
                activeRoute={location.pathname === "/donations"}
                placeholder={isExpanded ? "Donations" : null}
                isExpanded={isExpanded}
              />
            </Link>
            <Link to={"/membership"}>
              <LinkButton
                icon={<RememberMeIcon className="w-7 h-7" />}
                activeRoute={location.pathname === "/membership"}
                placeholder={isExpanded ? "Membership" : null}
                isExpanded={isExpanded}
              />
            </Link>
            <Link to={"/newsfeed"}>
              <LinkButton
                icon={<NewspaperIcon className="w-7 h-7" />}
                activeRoute={location.pathname === "/newsfeed"}
                placeholder={isExpanded ? "News Feed" : null}
                isExpanded={isExpanded}
              />
            </Link>
            <Link to={"/blogs"}>
              <LinkButton
                icon={<BookIcon className="w-7 h-7" />}
                activeRoute={location.pathname === "/blogs"}
                placeholder={isExpanded ? "Blog" : null}
                isExpanded={isExpanded}
              />
            </Link>
          </div>
          <h1 className="text-center mt-4 uppercase text-customBlack tracking-widest">
            Account pages
          </h1>
          <div className="w-full h-0.5 my-4 bg-primary" />
          <div className="flex flex-col gap-1">
            <Link to={"/account"}>
              <LinkButton
                icon={<PersonIcon className="w-7 h-7" />}
                activeRoute={location.pathname === "/account"}
                placeholder={isExpanded ? "Account" : null}
                isExpanded={isExpanded}
              />
            </Link>
            <Link onClick={(e) => handleLogout(e)}>
              {" "}
              <LinkButton
                icon={<LogoutIcon className="w-7 h-7" />}
                placeholder={isExpanded ? "Signout" : null}
                isExpanded={isExpanded}
              />
            </Link>
          </div>
        </div>
      </div>
    </aside>
  );
}

export default SideBar