export const showingLoading = (loading) => ({
  type: "IS_LOADING",
  payload: loading,
});
export const showingPassword = (pass) => ({
  type: "SHOW_PASSWORD",
  payload: pass,
});
export const showloggedIn = (logging) => ({
  type: "IS_LOGGED",
  payload: logging,
});
export const setUsername = (username) => ({
  type: "SET_USERNAME",
  payload: username,
});
export const setPassword = (password) => ({
  type: "SET_PASSWORD",
  payload: password,
});
export const setToken = (token) => ({
  type: "SET_TOKEN",
  payload: token,
});
export const loginSuccess = (token) => ({
  type: "LOGIN_SUCCESS",
  payload: token,
});
export const setloggeduser = (user) => ({
  type: "SET_LOGGEDUSER",
  payload: user,
});
export const setusertype = (type) => ({
  type: "SET_USERTYPE",
  payload: type,
});

export const logout = () => ({
  type: "LOGOUT",
});