const initialState = {
  username: "",
  password: "",
  type: "",
  loading: false,
  showAdminModule: false,
  allAdmins: [],
  error:""
};

export const adminReducer = (state = initialState, action) => {
   switch (action.type) {
     case "SHOW_ADMIN_MODULE":
       return {
         ...state,
         showAdminModule: action.payload,
       };
     case "SET_ADMIN_USERNAME":
       return {
         ...state,
         username: action.payload,
       };
     case "SET_ADMIN_PASSWORD":
       return {
         ...state,
         password: action.payload,
       };
     case "SET_ADMIN_TYPE":
       return {
         ...state,
         type: action.payload,
       };
     case "IS_ADMIN_LOADING":
       return {
         ...state,
         loading: action.payload,
       };
     case "FETCH_ADMIN_SUCCESS":
       return {
         ...state,
         allAdmins: action.payload,
         error: "",
       };
     case "FETCH_ADMIN_FAILURE":
       return {
         ...state,
         error: action.payload,
       };
     default:
       return state;
   }
};
