import React, { useEffect, useState } from "react";
import { DataGrid } from "@mui/x-data-grid";
import { Box, Button } from "@mui/material";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { fetchDonorsFailure, fetchDonorsSuccess } from "../../redux/actions/donerAction";
import { Link } from "react-router-dom";

const columns = [
  {
    field: "id",
    headerName: "Doner ID",
    width: 90,
    renderCell: (params) => (
      <Link to={`/donorDetail/${params.row.DonarId}`}>{params.row.id}</Link>
    ),
  },
  {
    field: "First_Name",
    headerName: "First name",
    width: 150,
    editable: true,
    renderCell: (params) => (
      <Link to={`/donorDetail/${params.row.DonarId}`}>
        {params.row.First_Name}
      </Link>
    ),
  },
  {
    field: "Last_Name",
    headerName: "Last name",
    width: 150,
    editable: true,
    renderCell: (params) => (
      <Link to={`/donorDetail/${params.row.DonarId}`}>
        {params.row.Last_Name}
      </Link>
    ),
  },
  {
    field: "Sex",
    headerName: "Sex",
    renderCell: (params) => (
      <Link to={`/donorDetail/${params.row.DonarId}`}>{params.row.Sex}</Link>
    ),
  },
  {
    field: "Phone",
    headerName: "Phone-number",
    width: 180,
    renderCell: (params) => (
      <Link to={`/donorDetail/${params.row.DonarId}`}>{params.row.Phone}</Link>
    ),
  },
  {
    field: "Amount",
    headerName: "Amount",
    width: 150,
    renderCell: (params) => (
      <Link to={`/donorDetail/${params.row.DonarId}`}>{params.row.Amount}</Link>
    ),
  },
  {
    field: "delete",
    headerName: "Delete",
    width: 100,
    renderCell: (params) => <DeleteButton DonarId={params.row.DonarId} />,
  },
];


const DeleteButton = ({ DonarId }) => {
  const dispatch = useDispatch();
  const token = useSelector((state) => state.login.accessToken);
  const [isLoading, setLoading] = useState(false);

  const fetchNews = () => {
    axios
      .get("https://api.octoberbreastcancer.com/api/Donar")
      .then((response) => {
        dispatch(fetchDonorsSuccess(response.data));
      })
      .catch((error) => {
        console.log(error.message);
      });
  };

  const handleDelete = async () => {
    try {
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };
      setLoading(true);

      await axios.delete(
        `https://api.octoberbreastcancer.com/api/Donar/Delete/${DonarId}`,
        config
      );
      fetchNews();
      console.log("Donar deleted successfully");
      setLoading(false);
    } catch (error) {
      console.error("Error deleting news:", error);
      setLoading(false);
    }
  };
   return (
     <Button
       variant="contained"
       color="error"
       disableElevation
       onClick={handleDelete}
       sx={{ width: 100 }}
     >
       {isLoading ? "..." : "Delete"}
     </Button>
   );
};


const DonationTable = () => {
  const dispatch = useDispatch();
  const donors = useSelector((state) => state.donors.donors);

  useEffect(() => {
    // Fetch data from the API using Axios.
    axios
      .get("https://api.octoberbreastcancer.com/api/Donar")
      .then((response) => {
        // Assuming your API response is an array of objects
          dispatch(fetchDonorsSuccess(response.data));
      })
      .catch((error) => {
        dispatch(fetchDonorsFailure(error.message));
      });
  }, [dispatch]);
  return (
    <Box>
      <DataGrid
        sx={{ marginRight: 2, background: "#fff" }}
        rows={donors}
        columns={columns}
        initialState={{
          pagination: {
            paginationModel: {
              pageSize: 5,
            },
          },
        }}
        pageSizeOptions={[5]}
        checkboxSelection
        disableRowSelectionOnClick
      />
    </Box>
  );
};

export default DonationTable;
