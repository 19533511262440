// Actions
export const addImage = (image) => ({
  type: "ADD_IMAGE",
  payload: image,
});

export const deleteImage = (index) => ({
  type: "DELETE_IMAGE",
  payload: index,
});

export const addNewsImage = (image) => ({
  type: "ADD_NEWS_IMAGE",
  payload: image,
});

export const reseNewstImages = () => ({
  type: "RESET_IMAGES",
});

export const deleteNewsImage = (index) => ({
  type: "DELETE_NEWS_IMAGE",
  payload: index,
});


export const selectImages = (files) => ({
  type: "SELECT_IMAGES",
  payload: files,
});


