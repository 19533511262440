// actions.js
export const FETCH_DONORS_SUCCESS = "FETCH_DONORS_SUCCESS";
export const FETCH_DONORS_FAILURE = "FETCH_DONORS_FAILURE";

export const fetchDonorsSuccess = (donors) => ({
  type: FETCH_DONORS_SUCCESS,
  payload: donors,
});

export const fetchDonorsFailure = (error) => ({
  type: FETCH_DONORS_FAILURE,
  payload: error,
});
