import { Person2TwoTone } from '@mui/icons-material'
import React from 'react'
import doners from '../assets/3d images/Doners.png'
import DonationTable from '../components/common/DonationTable';
import { useSelector } from 'react-redux';


const Donations = () => {
  const donors = useSelector((state) => state.donors.donors);
  return (
    <div className="mt-7 w-full">
      <div className="w-full flex items-center gap-12 relative p-10 bg-white shadow-lg rounded-xl h-32">
        <div className="p-3 w-32 bg-donationcolor flex items-center justify-center rounded-xl text-white shadow-xl absolute right-5 -top-5">
          <Person2TwoTone className="" />
        </div>
        <img src={doners} className="w-40 h-fit" alt="" />
        <div className="space-y-1">
          <h1 className="font-bold text-2xl uppercase tracking-widest text-customBlack">
            Doners
          </h1>
          <h1 className="font-bold text-3xl uppercase">
            {donors.length === 0 ? "..." : donors.length}
          </h1>
          <p className="capitalize tracking-wider">
            Total amount of{" "}
            <span className="text-donationcolor font-bold">doners</span>
          </p>
        </div>
      </div>
      <h1 className="font-bold uppercase text-customBlack mt-10 mb-4 tracking-wide">
        Doners List
      </h1>
      <DonationTable />
    </div>
  );
}

export default Donations