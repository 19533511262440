const initialState = {
  blogImage: [],
  blogTitle: "",
  blogBody: "",
  blogPublisher: "",
  videoURL:"",
  blogDate: "",
  success: "",
  error: "",
  loading: false,
  allBlogs: [],
};

export const blogReducer = (state = initialState,  action ) => {
  switch (action.type) {
    case "FETCH_BLOG_SUCCESS":
      return {
        ...state,
        allBlogs: action.payload,
        error: "",
      };
    case "FETCH_BLOG_FAILURE":
      return {
        ...state,
        error: action.payload,
      };
    case "SET_BLOG_TITLE":
      return {
        ...state,
        blogTitle: action.payload,
      };
    case "SET_BLOG_BODY":
      return {
        ...state,
        blogBody: action.payload,
      };
    case "SET_BLOG_PUBLISHER":
      return {
        ...state,
        blogPublisher: action.payload,
      };
    case "SET_BLOG_DATE":
      return {
        ...state,
        blogDate: action.payload,
      };
    case "SET_URL":
      return {
        ...state,
        videoURL: action.payload,
      };
    case "BLOG_LOADING_STATE":
      return {
        ...state,
        loading: action.payload,
      };
    case "BLOG_ERROR_STATE":
      return {
        ...state,
        error: action.payload,
      };
    case "BLOG_SUCCESS_STATE":
      return {
        ...state,
        success: action.payload,
      };

    default:
      return state;
  }
}
