import { Box, Container, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { LuHeartHandshake } from "react-icons/lu";
import { FaPeopleGroup } from "react-icons/fa6";
import { FaCircle } from "react-icons/fa6";
import { IoPersonSharp } from "react-icons/io5";
import { LuNewspaper } from "react-icons/lu";
import { FaBlog } from "react-icons/fa";
import {
  Tooltip,
  Legend,
  XAxis,
  YAxis,
  CartesianGrid,
  LineChart,
  Line,
} from "recharts";
//import { donations } from '../MockData/Data';
import axios from "axios";
import { useSelector } from "react-redux";

const Dashboard = () => {
  const [donorsCount, setDonorsCount] = useState("...");
  const token = useSelector((state) => state.login.accessToken);
  const [membersCount, setMembersCount] = useState("...");
  const [newsCount, setNewsCount] = useState("...");
  const [blogsCount, setBlogsCount] = useState("...");
  const [donations, setDonations] = useState([]);
  const [member, setMember] = useState([]);
  const isLogged = useSelector((state) => state.login.isLogged);

  useEffect(() => {
    if (isLogged) {
      const fetchData = async () => {
        // Introduce a delay of 1000 milliseconds
        await new Promise((resolve) => setTimeout(resolve, 5000));

        try {
          // Assuming you have the token available somewhere

          const responseDonar = await axios.get(
            "https://api.octoberbreastcancer.com/api/Report/TotalDonar",
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          );
          console.log(responseDonar.data);
          setDonorsCount(responseDonar.data);

          const responseNews = await axios.get(
            "https://api.octoberbreastcancer.com/api/Report/TotalNews",
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          );
          setNewsCount(responseNews.data);

          const responseMembers = await axios.get(
            "https://api.octoberbreastcancer.com/api/Report/TotalMembers",
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          );
          setMembersCount(responseMembers.data);

          const responseBlogs = await axios.get(
            "https://api.octoberbreastcancer.com/api/Report/TotalBlogs",
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          );
          setBlogsCount(responseBlogs.data);
          const responseDonars = await axios.get(
            "https://api.octoberbreastcancer.com/api/Report/Month/Donar"
          );
          setDonations(responseDonars.data);
          console.log(donations);
          const responseMember = await axios.get(
            "https://api.octoberbreastcancer.com/api/Report/Month/Member"
          );
          setMember(responseMember.data);
          console.log(member);
        } catch (error) {
          console.error("Error fetching data:", error);
        }
      };

      fetchData();
    }
  }, [token, isLogged,donations,member]);

  return (
    <Container>
      <Box
        style={{ width: "100%" }}
        display="grid"
        gridTemplateColumns="repeat(4, minmax(0, 1fr))"
        gap="4%"
        className="mt-10"
      >
        <Box
          className="rounded-[5%] shadow-lg"
          sx={{ gridColumn: "span 1", backgroundColor: "#77dd77", p: "9%" }}
        >
          <Box display="flex" justifyContent="space-between">
            <Typography fontWeight="bold" fontSize="1.3em">
              {" "}
              DONORS
            </Typography>
            <IoPersonSharp className="bg-white/70 w-16 h-16 p-4 rounded-[7%] text-[#77dd77] -mt-10 shadow-sm" />
          </Box>
          <Typography
            fontWeight="bold"
            fontSize="1.7em"
            sx={{ color: "#ffffff", marginTop: "3%", marginBottom: "3%" }}
          >
            {" "}
            {donorsCount}
          </Typography>
          <Typography
            fontWeight="bold"
            fontSize="1em"
            className="text-gray-200"
          >
            {" "}
            Total amount of <span className="text-[#000000]">Donors</span>
          </Typography>
        </Box>

        <Box
          className="rounded-[5%] shadow-lg"
          sx={{ gridColumn: "span 1", backgroundColor: "#f47c64", p: "9%" }}
        >
          <Box display="flex" justifyContent="space-between">
            <Typography fontWeight="bold" fontSize="1.3em">
              {" "}
              MEMBERS
            </Typography>
            <FaPeopleGroup className="bg-white/70 w-16 h-16 p-4 rounded-[7%] text-[#f47c64] -mt-10 shadow-sm" />
          </Box>
          <Typography
            fontWeight="bold"
            fontSize="1.7em"
            sx={{ color: "#ffffff", marginTop: "3%", marginBottom: "3%" }}
          >
            {" "}
            {membersCount}
          </Typography>
          <Typography
            fontWeight="bold"
            fontSize="1em"
            className="text-gray-200"
          >
            {" "}
            Total amount of <span className="text-[#000000]">Members</span>
          </Typography>
        </Box>

        <Box
          className="rounded-[5%] shadow-lg"
          sx={{ gridColumn: "span 1", backgroundColor: "#77c3ec", p: "9%" }}
        >
          <Box display="flex" justifyContent="space-between">
            <Typography fontWeight="bold" fontSize="1.3em">
              {" "}
              NEWS
            </Typography>
            <LuNewspaper className="bg-white/70 w-16 h-16 p-4 rounded-[7%] text-[#77c3ec] -mt-10 shadow-sm" />
          </Box>

          <Typography
            fontWeight="bold"
            fontSize="1.7em"
            sx={{ color: "#ffffff", marginTop: "3%", marginBottom: "3%" }}
          >
            {" "}
            {newsCount}
          </Typography>
          <Typography
            fontWeight="bold"
            fontSize="1em"
            className="text-gray-200"
          >
            {" "}
            Total amount of <span className="text-[#000000]">News</span>
          </Typography>
        </Box>

        <Box
          className="rounded-[5%] shadow-lg"
          sx={{ gridColumn: "span 1", backgroundColor: "#966fd6", p: "9%" }}
        >
          <Box display="flex" justifyContent="space-between">
            <Typography fontWeight="bold" fontSize="1.3em">
              {" "}
              BLOGS
            </Typography>
            <FaBlog className="bg-white/70 w-16 h-16 p-4 rounded-[7%] text-[#966fd6] -mt-10 shadow-sm" />
          </Box>
          <Typography
            fontWeight="bold"
            fontSize="1.7em"
            sx={{ color: "#ffffff", marginTop: "3%", marginBottom: "3%" }}
          >
            {" "}
            {blogsCount}
          </Typography>
          <Typography
            fontWeight="bold"
            fontSize="1em"
            className="text-gray-200"
          >
            {" "}
            Total amount of <span className="text-[#000000]">Blogs</span>
          </Typography>
        </Box>
      </Box>
      {/* Graph */}
      <Box
        display="grid"
        gridTemplateColumns="repeat(2, minmax(0, 1fr))"
        gap="5%"
        sx={{ marginTop: "7%", marginBottom: "7%" }}
      >
        <Box
          className="rounded-[5%] shadow-lg"
          sx={{
            gridColumn: "span 1",
            width: "100%", // Adjusted width to 100%
            backgroundColor: "#ffffff",
          }}
        >
          <Box display="flex" justifyContent="space-between" sx={{ p: "5%" }}>
            <Box display="flex">
              <LuHeartHandshake className="text-[3.5em] text-green-400 mr-2" />
              <Typography fontWeight="bold" fontSize="1.2em">
                Total Donations <br />{" "}
                <span className="text-green-400"> In Month </span>
              </Typography>
            </Box>
            <FaCircle className="text-[1em] text-green-400" />
          </Box>
          <div style={{ width: "100%", overflowX: "auto" }}>
            {" "}
            {/* Added div with overflow-x auto */}
            <LineChart
              width={donations.length * 38} // Adjusted width dynamically based on data length
              height={370}
              data={donations}
              className="pr-10"
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="year" />
              <YAxis />
              <Tooltip />
              <Legend />
              <Line dataKey="donation" color="#77dd77" fill="#77dd77" />
            </LineChart>
          </div>
        </Box>
        <Box
          className="rounded-[5%] shadow-lg "
          sx={{
            gridColumn: "span 1",
            width: "110%",
            backgroundColor: "#ffffff",
          }}
        >
          <Box display="flex" justifyContent="space-between" sx={{ p: "5%" }}>
            <Box display="flex">
              <FaPeopleGroup className="text-[3.5em] text-blue-400 mr-2" />
              <Typography fontWeight="bold" fontSize="1.2em">
                Total Members Gained <br />{" "}
                <span className="text-blue-400"> In Month </span>
              </Typography>
            </Box>
            <FaCircle className="text-[1em] text-blue-400" />
          </Box>
          <div style={{ width: "100%", overflowX: "auto" }}>
            <LineChart
              width={member.length * 37} // Adjusted width dynamically based on data length
              height={370}
              data={member}
              className="pr-10 "
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="year" />
              <YAxis />
              <Tooltip />
              <Legend />
              <Line dataKey="member" color="#77dd77" fill="#77dd77" />
            </LineChart>
          </div>
        </Box>
      </Box>
    </Container>
  );
};

export default Dashboard;
