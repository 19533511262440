import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import logo from "../assets/logo/Logo only.png";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/navigation";
import "swiper/css/thumbs";
//import { FreeMode, Thumbs, Autoplay, Navigation } from "swiper";
import { Delete } from "@mui/icons-material";
import {
  CalendarMonth,
  Newspaper,
  NewspaperTwoTone,
  Person2,
  TextFields,
} from "@mui/icons-material";
import { Button } from "@mui/material";
import axios from "axios";
import { fetchNewsSuccess } from "../redux/actions/newsAction";

const NewsDetail = () => {
  const { NewsId } = useParams();
  const dispatch = useDispatch();
  const allNews = useSelector((state) => state.news.allNews);
  const token = useSelector((state) => state.login.accessToken);
  const [isLoading, setLoading] = useState(false);
  const [images, setImages] = useState([]);
  const theNews = allNews.find((news) => news.NewsId === NewsId);

  const fetchNews = () => {
    axios
      .get("https://api.octoberbreastcancer.com/api/News")
      .then((response) => {
        dispatch(fetchNewsSuccess(response.data));
      })
      .catch((error) => {
        console.log(error.message);
      });
  };

  useEffect(() => {
    const fetchNewsImage = async () => {
      try {
        const news = allNews.find((news) => news.NewsId === NewsId);
        const imageUrls = [];

        for (let i = 1; i <= 5; i++) {
          const imageUrl = news[`Image_${i}`];
          if (imageUrl) {
            const response = await axios.get(
              `https://api.octoberbreastcancer.com/api/image/Get/News_feed/${NewsId}/${imageUrl}`,
              { responseType: "blob" }
            );
            const reader = new FileReader();
            reader.onload = () => {
              imageUrls.push(reader.result);
              if (imageUrls.length === 1) {
                setImages(imageUrls); // Set the image URLs array
              }
            };
            reader.readAsDataURL(response.data);
          }
        }
      } catch (error) {
        console.error("Error fetching news images:", error);
      }
    };

    if (theNews) {
      fetchNewsImage();
    }
  }, [theNews,NewsId,allNews]); 

  

  useEffect(() => {
    fetchNews();
  });


  const deleteNews = async () => {
    try {
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };
      setLoading(true);

      await axios.delete(
        `https://api.octoberbreastcancer.com/api/News/Delete/${NewsId}`,
        config
      );
      fetchNews();
      console.log("News deleted successfully");
      setLoading(false);
    } catch (error) {
      console.error("Error deleting news:", error);
      setLoading(false);
    }
  };

  if (!theNews) {
    return (
      <div className="w-full h-[30rem] flex items-center justify-center font-bold text-slate-600 text-lg capitalize">
        <div className="flex flex-col items-center">
          <NewspaperTwoTone className="text-primary" />
          News not found !
        </div>
      </div>
    );
  }

  return (
    <div>
      <div className="h-full flex flex-col items-center justify-center">
        <div className="bg-white relative overflow-hidden flex items-center justify-between shadow-md rounded-lg p-8 w-full">
          <div className="space-y-6">
            <img
              src={logo}
              className="absolute -top-16 -right-14 w-40 h-40 opacity-50"
              alt=""
            />
            <img
              src={logo}
              className="absolute -bottom-20 -left-16 -rotate-90 w-32 h-32 opacity-30"
              alt=""
            />
            <div className="flex uppercase items-center mb-4">
              <Newspaper className="text-primary text-4xl mr-2" />
              <span className="text-gray-700 font-semibold mr-3">Title: </span>
              <h2 className="text-3xl font-bold text-gray-800">
                {theNews.Title}
              </h2>
            </div>

            <div className="flex items-start space-x-2">
              <TextFields className="text-primary text-xl" />
              <span className="text-gray-700 font-semibold">Article:</span>
              <h1 className="w-80">{theNews.Body}</h1>
            </div>
            <div className="flex items-center space-x-2">
              <Person2 className="text-primary text-xl" />
              <span className="text-gray-700 font-semibold">Publisher:</span>
              <span className="text-gray-800">{theNews.Author}</span>
            </div>
            <div className="flex items-center space-x-2">
              <CalendarMonth className="text-primary text-xl" />
              <span className="text-gray-700 font-semibold">Date:</span>
              <span className="text-gray-800">{theNews.createdAt}</span>
            </div>
            <div className="flex items-center space-x-2">
              <Button
                className="text-gray-700 font-bold"
                style={{
                  backgroundColor: "#FF7FCE",
                  borderRadius: 8,
                  color: "#fff",
                  paddingTop: 12,
                  paddingBottom: 12,
                  height: 40,
                }}
                startIcon={<Delete className="text-white text-xl" />}
                onClick={() => deleteNews()}
                disabled={isLoading}
              >
                {!isLoading ? "Remove News" : "Deleting News..."}
              </Button>
            </div>
          </div>
          <div className="w-2/4 h-full">
            <Swiper
              navigation
              className="w-full h-full rounded-lg"
              style={{ border: "1px solid #e0e0e0" }}
            >
              {images.map((image, index) => (
                <SwiperSlide key={index}>
                  <img
                    className="w-full h-full rounded-lg object-cover"
                    src={image}
                    alt=""
                  />
                </SwiperSlide>
              ))}
            </Swiper>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NewsDetail;
