import { FaPeopleGroup } from "react-icons/fa6";
import membersImage from '../assets/3d images/Members.png'
import MembershipTable from '../components/common/MembershipTable';
import { useSelector } from "react-redux";

const Membership = () => {
  const members = useSelector((state) => state.members.members);

  return (
    <div className="mt-7 w-full">
      <div className="w-full flex items-center gap-12 relative p-10 bg-white shadow-lg rounded-xl h-32">
        <div className="p-4 w-32 bg-membershipcolor flex items-center justify-center rounded-xl text-white shadow-xl absolute right-5 -top-5">
          <FaPeopleGroup className="w-60" />
        </div>
        <img src={membersImage} className="w-40 p-1 h-fit" alt="" />
        <div className="space-y-1">
          <h1 className="font-bold text-2xl uppercase tracking-widest text-customBlack">
            Members
          </h1>
          <h1 className="font-bold text-3xl uppercase">{members.length===0?"...":members.length}</h1>
          <p className="capitalize tracking-wider">
            Total amount of{" "}
            <span className="text-membershipcolor font-bold">members</span>
          </p>
        </div>
      </div>
      <h1 className="font-bold uppercase text-customBlack mt-10 mb-4 tracking-wide">
        Members List
      </h1>
      <MembershipTable />
    </div>
  );
}

export default Membership

//#f47c64