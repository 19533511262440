import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import successImage from "../../assets/3d images/Success.png";
import { Button } from "@mui/material";
import { DoneAllRounded } from "@mui/icons-material";
import { isSuccess } from "../../redux/actions/newsAction";

const SuccessModule = () => {
  const success = useSelector((state) => state.news.success);
  const dispatch = useDispatch();

  useEffect(() => {
    if (success !== "") {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "scroll";
    }
  }, [success]);

  return (
    success !== "" && (
      <div className="fixed inset-0 z-[100] flex items-center justify-center bg-black bg-opacity-20">
        <div className="bg-white p-6 sm:p-8 rounded-lg w-11/12 sm:w-3/4 md:w-2/3 lg:w-1/2 xl:w-1/3 relative flex flex-col items-center">
          <img
            className="w-24 h-24 sm:w-32 sm:h-32 absolute -top-12 sm:-top-16"
            src={successImage}
            alt="Success"
          />
          <div className="mt-16 sm:mt-24 text-center px-4 sm:px-8">
            <h1 className="font-black text-customBlack text-xl sm:text-2xl md:text-3xl uppercase tracking-wide break-words">
              {success}
            </h1>
            <p className="font-semibold text-primary text-md sm:text-lg uppercase tracking-wide mt-4">
              The post has been published
              <br />
              successfully.
            </p>
            <Button
              style={{
                backgroundColor: "#FF7FCE",
                borderRadius: 8,
                color: "#fff",
                paddingTop: 8,
                paddingBottom: 8,
                marginTop: 20,
                fontSize: 14,
                width: "100%",
                maxWidth: 310,
              }}
              startIcon={<DoneAllRounded />}
              onClick={() => dispatch(isSuccess(""))}
            >
              Done!
            </Button>
          </div>
        </div>
      </div>
    )
  );
};

export default SuccessModule;
