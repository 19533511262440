export const showAdminModule = (isShowing) => ({
  type: "SHOW_ADMIN_MODULE",
  payload: isShowing,
});

export const setAdminUsername = (username) => ({
  type: "SET_ADMIN_USERNAME",
  payload: username,
});
export const setAdminPassword = (password) => ({
  type: "SET_ADMIN_PASSWORD",
  payload: password,
});
export const setAdminType = (type) => ({
  type: "SET_ADMIN_TYPE",
  payload: type,
});
export const showingAdminLoading = (loading) => ({
  type: "IS_ADMIN_LOADING",
  payload: loading,
});
export const fetchAdminsSuccess = (admins) => ({
  type: "FETCH_ADMIN_SUCCESS",
  payload: admins,
});

export const fetchAdminsFailure = (error) => ({
  type: "FETCH_ADMIN_FAILURE",
  payload: error,
});
